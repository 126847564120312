export default [

  // *===============================================---*
  // *--------- DEVICES ---- ---------------------------------------*
  // *===============================================---*
  //   {
  //     path: '/devices/list',
  //     name: 'apps-devices-list',
  //     component: () => import('@/views/devices/devices-list/DevicesList.vue'),
  //   },
  {
    path: '/devices/edit/:id',
    name: 'apps-devices-edit',
    component: () => import('@/views/devices/devices-edit/DevicesEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Dispositivos',
          active: false,
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

]
