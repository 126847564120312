import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './routes/apps'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/monitor',
      name: 'monitor',
      component: () => import('@/views/monitor/Monitor.vue'),
      meta: {
        pageTitle: 'Monitoramento',
        breadcrumb: [
          {
            text: 'Monitoramento',
            active: true,
          },
        ],
      },  
    },
    {
      path: '/monitor/details',
      name: 'details',
      component: () => import('@/views/monitor/details/MonitorDetails.vue'),
      meta: {
        pageTitle: 'Detalhes',
        breadcrumb: [
          {
            text: 'Monitoramento',
            active: false,
          },
          {
            text: 'Detalhes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/assets',
      name: 'assets',
      component: () => import('@/views/assets/Assets.vue'),
      meta: {
        pageTitle: 'Ativos',
        breadcrumb: [
          {
            text: 'Ativos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/Devices.vue'),
      meta: {
        pageTitle: 'Dispositivos',
        breadcrumb: [
          {
            text: 'Dispositivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/geolocation',
      name: 'geolocation',
      component: () => import('@/views/Geolocation.vue'),
      meta: {
        pageTitle: 'Geolocalização',
        breadcrumb: [
          {
            text: 'Geolocalização',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/user/Account.vue'),
      meta: {
        pageTitle: 'Infomações da Conta',
        breadcrumb: [
          {
            text: 'Minha Conta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/Help.vue'),
      meta: {
        pageTitle: 'Ajuda',
        breadcrumb: [
          {
            text: 'Ajuda',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
